<template>
    <div class="container">
        <main>
        <article>
        <p class="title" style="font-size: 28px; margin-bottom: 10px;">宝德仕祝您端午安康！</p>
        <p class="time" style="font-size: 18px; margin-bottom: 20px;">2022-06-03 23:44</p>
        <p>今日五月初五端午，</p>
        <p>愿每个人都能品尝时光的香甜，</p>
        <p>在仲夏的日子里平和安康</p></article>
        <picture><img src="../../static/img/consultCenterImg/news-6/1.png" style="width: 25%" alt=""></picture>
        <article>
            <p>6月1日下午17：00</p>
            <p>管理部于一楼休闲区向所有在职员工派发端午节大礼包</p>
            <p>祝全体员工端午安康！</p>
        </article>
        <picture><img src="../../static/img/consultCenterImg/news-6/2.png" style="width: 80%" alt="">
        <img src="../../static/img/consultCenterImg/news-6/3.png" style="width: 80%" alt="">
        <img src="../../static/img/consultCenterImg/news-6/4.png" style="width: 40%" alt=""></picture>
    </main></div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: bold;
        color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    p{
        margin: auto;
        text-align: center;
    }

    img{
        display: block;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }

</style>